import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Headline1, Headline2, Headline3, Subtitle1, Subtitle2, Body1, Body2, Caption1, Caption2 } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE`}</strong></p>
      <ul parentName="blockquote">
        <li parentName="ul">{`v3부터 Bodies에 `}<inlineCode parentName="li">{`paragaph`}</inlineCode>{` 속성을 지원하지 않습니다.`}</li>
        <li parentName="ul">{`v6부터는 margin 스타일을 제어하는 속성을 따로 지원하지 않습니다.`}</li>
      </ul>
    </blockquote>
    <p>{`Class101 UI의 Typography는 기기나 뷰포트 크기가 증가함에 따라 적절하게 확대되는 반응형, 모바일 우선 유동 글자 시스템을 지원합니다.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Headline1>H1 heading</Headline1>\n<Headline2>H2 heading</Headline2>\n<Headline3>H3 heading</Headline3>\n<Subtitle1>H4 subtitle1</Subtitle1>\n<Subtitle1>H4 subtitle2</Subtitle1>\n<Body1>P and div body1</Body1>\n<Body2>P and div body2</Body2>\n<Caption1>Div caption1</Caption1>\n<Caption2>Div caption2</Caption2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Headline1 mdxType="Headline1">H1 heading</Headline1>
  <Headline2 mdxType="Headline2">H2 heading</Headline2>
  <Headline3 mdxType="Headline3">H3 heading</Headline3>
  <Subtitle1 mdxType="Subtitle1">H4 subtitle1</Subtitle1>
  <Subtitle1 mdxType="Subtitle1">H4 subtitle2</Subtitle1>
  <Body1 mdxType="Body1">P and div body1</Body1>
  <Body2 mdxType="Body2">P and div body2</Body2>
  <Caption1 mdxType="Caption1">Div caption1</Caption1>
  <Caption2 mdxType="Caption2">Div caption2</Caption2>
    </Playground>
    <h2 {...{
      "id": "element"
    }}>{`element`}</h2>
    <p><inlineCode parentName="p">{`element`}</inlineCode>{`를 사용해서 다음의 태그들을 적용할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{}}>{`element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
`}</code></pre>
    <Playground __position={1} __code={'<Headline1 element=\"h3\">H1 heading or H2 heading</Headline1>\n<Subtitle1 element=\"h1\">H1 heading or H2 heading</Subtitle1>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Headline1 element="h3" mdxType="Headline1">H1 heading or H2 heading</Headline1>
  <Subtitle1 element="h1" mdxType="Subtitle1">H1 heading or H2 heading</Subtitle1>
    </Playground>
    <h2 {...{
      "id": "responsive-examples"
    }}>{`Responsive examples`}</h2>
    <p>{`lg: >= 1024px `}<br />{`
sm: < 1023px`}</p>
    <p>{`데스크탑(lg)과 모바일(sm)에서 스타일이 바뀝니다! 브라우저 창의 가로를 늘렸다 줄였다 해보세요!`}<br /></p>
    <pre><code parentName="pre" {...{}}>{`'Headline1' | 'Headline2' | 'Headline1' | 'Subtitle1' | 'Body1' | 'Body2' | 'Caption1' | 'Caption2'
`}</code></pre>
    <Playground __position={2} __code={'<Headline1 sm=\"Headline2\">H1 heading or H2 heading</Headline1>\n<Body1 sm=\"Body2\">DIV body1 or DIV body2</Body1>\n<Caption1 sm=\"Caption2\">DIV caption1 or DIV caption2</Caption1>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Headline1 sm="Headline2" mdxType="Headline1">H1 heading or H2 heading</Headline1>
  <Body1 sm="Body2" mdxType="Body1">DIV body1 or DIV body2</Body1>
  <Caption1 sm="Caption2" mdxType="Caption1">DIV caption1 or DIV caption2</Caption1>
    </Playground>
    <h2 {...{
      "id": "headings"
    }}>{`Headings`}</h2>
    <p>{`Headings는 더 큰 텍스트 스타일인 `}<inlineCode parentName="p">{`display: 2 | 3`}</inlineCode>{`를 지원합니다.`}</p>
    <Props of={Headline1} mdxType="Props" />
    <Playground __position={4} __code={'<Headline1 display={2}>H1 display2</Headline1>\n<Headline1 display={3}>H1 display3</Headline1>\n<Headline1>H1 heading</Headline1>\n<Headline2>H2 heading</Headline2>\n<Headline3>H3 heading</Headline3>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Headline1 display={2} mdxType="Headline1">H1 display2</Headline1>
  <Headline1 display={3} mdxType="Headline1">H1 display3</Headline1>
  <Headline1 mdxType="Headline1">H1 heading</Headline1>
  <Headline2 mdxType="Headline2">H2 heading</Headline2>
  <Headline3 mdxType="Headline3">H3 heading</Headline3>
    </Playground>
    <h2 {...{
      "id": "subtitle"
    }}>{`Subtitle`}</h2>
    <Props of={Subtitle1} mdxType="Props" />
    <Playground __position={6} __code={'<Subtitle1>H4 subtitle1</Subtitle1>\n<Subtitle2>H4 subtitle2</Subtitle2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Subtitle1 mdxType="Subtitle1">H4 subtitle1</Subtitle1>
  <Subtitle2 mdxType="Subtitle2">H4 subtitle2</Subtitle2>
    </Playground>
    <h2 {...{
      "id": "bodies"
    }}>{`Bodies`}</h2>
    <Props of={Body1} mdxType="Props" />
    <Playground __position={8} __code={'<Body1>\n  Body1. Lorem Ipsum is simply dummy text of the printing and typesetting\n  industry. Lorem Ipsum has been the industry\'s standard dummy text ever since\n  the 1500s, when an unknown printer took a galley of type and scrambled it to\n  make a type specimen book. It has survived not only five centuries, but also\n  the leap into electronic typesetting, remaining essentially unchanged. It\n  was popularised in the 1960s with the release of Letraset sheets containing\n  Lorem Ipsum passages, and more recently with desktop publishing software\n  like Aldus PageMaker including versions of Lorem Ipsum.\n</Body1>\n<Body2>\n  Body2. Lorem Ipsum is simply dummy text of the printing and typesetting\n  industry. Lorem Ipsum has been the industry\'s standard dummy text ever since\n  the 1500s, when an unknown printer took a galley of type and scrambled it to\n  make a type specimen book. It has survived not only five centuries, but also\n  the leap into electronic typesetting, remaining essentially unchanged. It\n  was popularised in the 1960s with the release of Letraset sheets containing\n  Lorem Ipsum passages, and more recently with desktop publishing software\n  like Aldus PageMaker including versions of Lorem Ipsum.\n</Body2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Body1 mdxType="Body1">
    Body1. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
    to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
    typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
    containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
    versions of Lorem Ipsum.
  </Body1>
  <Body2 mdxType="Body2">
    Body2. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
    to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
    typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
    containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
    versions of Lorem Ipsum.
  </Body2>
    </Playground>
    <h2 {...{
      "id": "captions"
    }}>{`Captions`}</h2>
    <Props of={Caption1} mdxType="Props" />
    <Playground __position={10} __code={'<Caption1>Div caption2</Caption1>\n<Caption2>Div caption2</Caption2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Caption1 mdxType="Caption1">Div caption2</Caption1>
  <Caption2 mdxType="Caption2">Div caption2</Caption2>
    </Playground>
    <h2 {...{
      "id": "with-diffrent-colors"
    }}>{`With diffrent colors`}</h2>
    <Playground __position={11} __code={'<Headline1 color=\"orange\">Lorem ipsum dolor sit amet</Headline1>\n<Subtitle1 color=\"red\">1914 translation by H. Rackham</Subtitle1>\n<Body1 color=\"green\">\n  Section 1.10.32 of \"de Finibus Bonorum et Malorum\", written by Cicero in 45\n  BC\n</Body1>\n<Caption1 color=\"pink\">\n  Section 1.10.33 of \"de Finibus Bonorum et Malorum\", written by Cicero in 45\n  BC\n</Caption1>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Headline1 color="orange" mdxType="Headline1">Lorem ipsum dolor sit amet</Headline1>
  <Subtitle1 color="red" mdxType="Subtitle1">1914 translation by H. Rackham</Subtitle1>
  <Body1 color="green" mdxType="Body1">Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</Body1>
  <Caption1 color="pink" mdxType="Caption1">Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</Caption1>
    </Playground>
    <h2 {...{
      "id": "text-align"
    }}>{`text align`}</h2>
    <Playground __position={12} __code={'<Headline1 textAlign={\'center\'}>Lorem ipsum dolor sit amet</Headline1>\n<Headline2 textAlign={\'center\'}>Lorem ipsum dolor sit amet</Headline2>\n<Headline3 textAlign={\'center\'}>Lorem ipsum dolor sit amet</Headline3>\n<Subtitle1 textAlign={\'center\'}>1914 translation by H. Rackham</Subtitle1>\n<Subtitle2 textAlign={\'center\'}>1914 translation by H. Rackham</Subtitle2>\n<Body1 textAlign={\'center\'}>\n  Section 1.10.32 of \"de Finibus Bonorum et Malorum\", written by Cicero in 45\n  BC\n</Body1>\n<Body2 textAlign={\'center\'}>\n  Section 1.10.32 of \"de Finibus Bonorum et Malorum\", written by Cicero in 45\n  BC\n</Body2>\n<Caption1 textAlign={\'center\'}>\n  Section 1.10.33 of \"de Finibus Bonorum et Malorum\", written by Cicero in 45\n  BC\n</Caption1>\n<Caption2 textAlign={\'center\'}>\n  Section 1.10.33 of \"de Finibus Bonorum et Malorum\", written by Cicero in 45\n  BC\n</Caption2>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Headline1,
      Headline2,
      Headline3,
      Subtitle1,
      Subtitle2,
      Body1,
      Body2,
      Caption1,
      Caption2,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Headline1 textAlign={'center'} mdxType="Headline1">Lorem ipsum dolor sit amet</Headline1>
  <Headline2 textAlign={'center'} mdxType="Headline2">Lorem ipsum dolor sit amet</Headline2>
  <Headline3 textAlign={'center'} mdxType="Headline3">Lorem ipsum dolor sit amet</Headline3>
  <Subtitle1 textAlign={'center'} mdxType="Subtitle1">1914 translation by H. Rackham</Subtitle1>
  <Subtitle2 textAlign={'center'} mdxType="Subtitle2">1914 translation by H. Rackham</Subtitle2>
  <Body1 textAlign={'center'} mdxType="Body1">Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</Body1>
  <Body2 textAlign={'center'} mdxType="Body2">Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</Body2>
  <Caption1 textAlign={'center'} mdxType="Caption1">
    Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
  </Caption1>
  <Caption2 textAlign={'center'} mdxType="Caption2">
    Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
  </Caption2>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      